import { ForwardedRef, useState, forwardRef } from 'react';
import { Text, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { typography } from 'ui';
import { Button } from '../../../atoms';
import { FeaturesDesc } from 'components/molecules';
import { FEATURES_CONTENT } from '../../../../constants/features';
import { useRouter } from 'next/router';

const Features = forwardRef(({ id }: any, ref: ForwardedRef<HTMLElement>) => {
  const [selectedSection, setSelectedSection] = useState(0);
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { locale } = useRouter();
  const handleImageChange = (index: number) => {
    setSelectedSection(index);
  };

  return (
    <section
      id={id}
      ref={ref}
      className="flex flex-col max-w-xl mx-auto mb-16 mr-0 features"
    >
      <div className="w-full">
        <div className="mb-10 sm:mb-8">
          <Text
            as="h3"
            className="border-l-[6px] border-primary-500 pl-4 font-Rubik text-black-secondary"
            {...(isMobile ? typography?.h5 : typography?.h4)}
          >
            {t('Our Business')}
            <span className="text-primary-500">{t(' Co-Creation ')}</span>
            {t('Features')}
          </Text>
        </div>
        <div className="grid grid-cols-[100%] lg:grid-cols-[400px_1fr]">
          <div className="features__des">
            {FEATURES_CONTENT.map((content, index) => (
              <FeaturesDesc
                key={index}
                index={index}
                heading={content.heading}
                paragraph={content.paragraph}
                hoverClass={content.class}
                imageName={
                  locale?.startsWith('ja')
                    ? content.imageName
                    : content.imageName + '-en'
                }
                imageAlt={content.imageAlt}
                handleImageChange={handleImageChange}
                selected={selectedSection === index}
              />
            ))}
            <Button
              btnVarient="link"
              href={'/service'}
              type="secondary"
              className="!w-full "
            >
              {t('Explore more about services')}
            </Button>
          </div>
          <div className="hidden place-self-stretch rounded-r-md rounded-bl-md bg-[#E5F0FF] p-8 lg:block">
            <div
              className={`h-full w-full animate-fade-in bg-contain bg-center bg-no-repeat`}
              style={{
                backgroundImage: `url(${
                  process.env.NEXT_PUBLIC_STATIC_URL
                }/features/${
                  FEATURES_CONTENT[selectedSection].imageName +
                  (!locale?.startsWith('ja') ? '-en' : '')
                }.png)`,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export { Features };
