import { ReactNode } from 'react';
import { Input, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { typography } from 'ui';

interface InputProps {
  value?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  onChange?: any;
  type?: string;
  name?: string;
  icon?: ReactNode;
  label?: string;
  required?: boolean;
  placeholder?: string;
  labelDescription?: string;
  rest?: any;
  id?: string;
}

const TextField = ({
  value,
  onChange,
  errorMessage,
  isInvalid,
  type,
  name,
  icon,
  label,
  required,
  placeholder,
  labelDescription,
  rest,
  id,
}: InputProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${labelDescription ? 'h-40' : 'h-32'} ${
        errorMessage ? 'mb-4' : ''
      }`}
    >
      <div className="flex items-center">
        {icon}
        <Text
          as="label"
          className={`
           ${icon && 'ml-[10px]'}
        inline font-Poppins text-[18px] font-normal leading-[26px] text-gray-primary`}
        >
          {label}
        </Text>
        {required && (
          <Text
            as="span"
            className="pl-[3px] font-Poppins text-[18px] font-normal leading-[26px] text-gray-secondary"
          >
            {t('(Required)')}
          </Text>
        )}
      </div>
      {labelDescription && (
        <Text
          {...typography?.extraSmall}
          className="mb-[5px] mt-[10px] text-[#50586C] "
          as="p"
        >
          {labelDescription}
        </Text>
      )}
      <Input
        id={id}
        {...rest}
        w="100%"
        focusBorderColor="#960912"
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        errorBorderColor="#960912"
        placeholder={placeholder}
        className={`mt-3 rounded-lg border p-4 ${
          !isInvalid && '!border-gray-secondary'
        } focus:border focus:border-primary-500 focus:outline-2 focus:outline-primary-400`}
        height="56px"
      />
      {errorMessage && (
        <Text className="mt-3 text-[#960912]" height="25px" as="p">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default TextField;
