import { Text } from '@chakra-ui/react';
import { TechFeature } from 'components/molecules';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'ui';

const TechGrowth = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section id={id} ref={ref} className="mr-0 lg:mr-[30px] xl:mr-[0px]">
        <TechFeature withMargin title={t('Sustainable Business Growth')}>
          <Text
            as="p"
            className="mb-6 font-Poppins text-white"
            {...typography?.medium}
          >
            {t(
              'To ensure that technical debt does not become a bottleneck for business growth in the medium to long term, we make sure that no technical debt is left behind from the early stages of the project. We may intentionally lower quality criteria depending on the stage of the business, budget, etc., but we will address these issues as early as possible as the business grows.'
            )}
          </Text>
        </TechFeature>
      </section>
    );
  }
);

export { TechGrowth };
