import { useContext, useState } from 'react';
import { ListItem } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Text } from '@chakra-ui/react';
import { typography } from 'ui';
import { INavbarConfig } from 'shared/interfaces';
import { Context } from 'utils/Context';

type Props = {
  item: INavbarConfig;
  onClick: () => void;
};

const SideBarItem = ({ item, onClick }: Props) => {
  const { selectedMenu } = useContext(Context);
  const [hoveredId, setHoveredId] = useState<string>('');
  const isActive = selectedMenu === item.id;

  const { t } = useTranslation();

  return (
    <ListItem
      cursor="pointer"
      onClick={onClick}
      fontFamily="'Poppins', sans-serif"
      {...typography?.normal}
      onMouseEnter={() => setHoveredId(item.id || '')}
      onMouseLeave={() => setHoveredId('')}
      textAlign="start"
      display="flex"
      fontWeight={isActive ? '600' : '400'}
      className="hover:ml-0 hover:font-[700] "
    >
      <div className="relative w-[64px]">
        <hr
          className={`absolute right-0 mt-[12px] border-primary-900 transition-all hover:w-[5.7rem] ${
            isActive || hoveredId === item.id ? 'w-[64px]' : 'w-[24px]'
          }`}
        />
      </div>
      <Text
        as="p"
        {...typography?.fontPoppins}
        className={`ml-[16px] capitalize text-primary-900 transition-all`}
      >
        {t(`${item.title}`)}
      </Text>
    </ListItem>
  );
};

export default SideBarItem;
