import { DevelopmentPreparation } from 'components/molecules/Preparation';
import { ForwardedRef, forwardRef } from 'react';
import Requirement from '../../../../public/requirement.svg';
import Prototype from '../../../../public/prototyping.svg';
import Api from '../../../../public/api.svg';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { typography } from 'ui';
import { useRouter } from 'next/router';
import { PageProps } from 'shared/interfaces';

export const QualityPreparationList = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const { locale } = useRouter();

    return (
      <section
        id={id}
        ref={ref}
        className="3xl:mr-0 mb-20 mt-9 md:mb-16 md:mr-6"
      >
        <div className="border-primary-500 mb-8 border-l-[6px] pl-4 lg:mb-12 ">
          <Text
            as="h3"
            className="font-Rubik text-black-secondary md:text-medium md:leading-medium mb-4 text-2xl font-bold leading-8"
          >
            {t('Development Preparation')}
          </Text>
          <Text
            as="p"
            className="font-Poppins text-gray-secondary inline"
            {...typography?.medium}
          >
            {t(
              'By conducting requirements definition and design in a systematic, rule-based manner, we prevent many rework and problems caused by inadequacies in the upstream process.'
            )}
          </Text>
        </div>

        <div className="md:gap-4 lg:grid lg:grid-cols-3 lg:gap-y-16">
          <DevelopmentPreparation
            icon={<Requirement />}
            title={t('Requirement Definition and System Design')}
            description={
              locale?.startsWith('en') ? (
                <div className="mt-4">
                  <Text className="text-gray-secondary inline">
                    {t('Create ')}
                  </Text>

                  <a
                    href="https://affordd.jp/libraries/affordd-conference2021-t02/"
                    target={'_blank'}
                    className="text-primary-500 underline"
                  >
                    {t('USDM-based ')}
                  </a>

                  <Text className="text-gray-secondary inline text-base">
                    {t(
                      'requirements specifications and improve the quality by reviewing and approving them. Documentation is kept up-to-date by adding updating to the development flow.'
                    )}
                  </Text>
                </div>
              ) : (
                <div className="mt-4 lg:mt-[46px]">
                  <a
                    href="https://affordd.jp/libraries/affordd-conference2021-t02/"
                    target={'_blank'}
                    className="text-primary-500 underline"
                  >
                    {t('USDM')}
                  </a>

                  <Text className="text-gray-secondary inline text-base">
                    {t(
                      ' ベースの要求仕様書を作成し、レビュー・承認によってその質を上げています。ドキュメントの更新を開発フローに加えることで、最新の状態に保ちます。'
                    )}
                  </Text>
                </div>
              )
            }
          />
          <DevelopmentPreparation
            icon={<Api />}
            title={t('API Design')}
            description={
              <div className="mt-4 lg:mt-[46px]">
                <Text className="text-gray-secondary inline">
                  {t('Design and documentation of APIs using the ')}
                </Text>
                <a
                  href="https://www.openapis.org/"
                  target={'_blank'}
                  className="text-primary-500 underline"
                >
                  {t('OpenAPI ')}
                </a>
                <Text className="text-gray-secondary inline text-base">
                  {t('specification, a highly versatile, global standard.')}
                </Text>
              </div>
            }
          />
          <DevelopmentPreparation
            icon={<Prototype />}
            title={t('Prototyping')}
            description={
              <Text className="text-gray-secondary mt-4  text-base lg:mt-[46px]">
                {t(
                  'Start prototyping from the initial stages. Embodying requirements helps foster a common understanding and allows everyone to share ideas.'
                )}
              </Text>
            }
          />
        </div>
      </section>
    );
  }
);
