import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { MEMBERS, TOP_LEVEL } from 'constants/members';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'ui';
import DownIcon from '../../../../assets/down.svg';

interface BoxProps {
  title: string;
  withIcon?: boolean;
  rotateIcon?: boolean;
  className?: string;
}

const QualityOfOrganization = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const router = useRouter();

    const Divider = () => (
      <div className="border-gray-secondary m-auto h-[32px] w-1 border-l-[1px] border-dashed " />
    );

    const Box = ({ title, withIcon, rotateIcon, className }: BoxProps) => (
      <div
        className={`border-gray-secondary relative flex min-h-[60px] w-full items-center justify-center border ${
          !rotateIcon && withIcon ? 'mb-8' : 'mb-0'
        } ${className}`}
      >
        <Text
          className="text-gray-primary !font-semibold"
          {...typography?.normal}
        >
          {t(title)}
        </Text>
        {withIcon && (
          <div
            className={`absolute right-6 transition-all ${
              rotateIcon ? 'rotate-0' : 'rotate-180'
            }`}
          >
            <DownIcon />
          </div>
        )}
      </div>
    );
    return (
      <section
        id={id}
        ref={ref}
        className="3xl:pr-0 mt-[10px] pr-0 md:mb-16 lg:pr-[60px] xl:pr-[60px]"
      >
        <div className="border-primary-500 border-l-[6px] pl-4">
          <Text as="h4" {...typography?.h4} className="text-gray-primary mb-4">
            {t('Quality of Organization')}
          </Text>
          <Text
            className="text-gray-secondary whitespace-pre-line"
            {...typography?.normal}
          >
            {router.locale === 'ja' ? (
              <>
                社名「WesionaryTEAM」が 「We are the Visionary Team」
                に由来しています。パートナーの事業ビジョンに伴走し、共に、より良い社会を実現するために集まっています。私たちの
                <Link href="/company" passHref>
                  <a className="text-primary-500 underline">
                    ビジョンとミッション
                  </a>
                </Link>
                、
                <Link href="/core-values" passHref>
                  <a className="text-primary-500 underline">コア・バリュー</a>
                </Link>
                を体現するため、推進体制を構築しています。採用に細心の注意を払い、継続的に啓蒙活動を行なっています。
              </>
            ) : (
              <>
                The company name "WesionaryTEAM" is derived from ‘We are the
                Visionary Team’. We come together to accompany our partners'
                business visions and work together to realize a better society.
                We are building a promotion system to embody our{' '}
                <Link href="/company" passHref>
                  <a className="text-primary-500 underline">
                    vision and mission
                  </a>
                </Link>
                , and{' '}
                <Link href="/core-values" passHref>
                  <a className="text-primary-500 underline">core values</a>
                </Link>
                . We pay close attention to recruitment and continuously educate
                our members.
              </>
            )}
            {t(
              '\n\nIn addition, we have established a Compliance Manual, and our Internal Control Department ensures that all members comply with laws, regulations, and social norms.'
            )}
          </Text>
        </div>
        <div className="relative mt-8 hidden md:block">
          <img
            alt="Organization Structure"
            src={`
              ${process.env.NEXT_PUBLIC_STATIC_URL}${
              router.locale === 'ja'
                ? '/organization/organization_jp.png'
                : '/organization/organization_en.png'
            }
            `}
            className="object-contain"
          />
        </div>
        <div className="block md:hidden">
          {TOP_LEVEL.map((department: string, index: number) => (
            <div key={index}>
              <Divider />
              <Box title={department} />
            </div>
          ))}
          <Divider />
          <Accordion defaultIndex={[0]} allowMultiple>
            {MEMBERS.map((peopleData, index: number) => (
              <AccordionItem isDisabled={!peopleData.list?.length} key={index}>
                {({ isExpanded }: any) => (
                  <>
                    <AccordionButton>
                      <Box
                        title={t(peopleData.title)}
                        withIcon={peopleData.list && peopleData.list.length > 0}
                        rotateIcon={isExpanded}
                        className={`${
                          peopleData.list?.length ? 'mb-0' : 'mb-8'
                        }`}
                      />
                    </AccordionButton>
                    <AccordionPanel className="border-gray-secondary mb-8 border border-t-0 px-4 pt-4">
                      <UnorderedList className="mb-4">
                        {peopleData?.list?.map((list, key) =>
                          list === 'Backend' || list === 'Security' ? (
                            <ListItem
                              key={`list-${key}`}
                              className="text-gray-secondary"
                              {...typography?.small}
                            >
                              {list}
                            </ListItem>
                          ) : (
                            <ListItem
                              key={`list-${key}`}
                              className="text-gray-secondary"
                              {...typography?.small}
                            >
                              {t(list)}
                            </ListItem>
                          )
                        )}
                      </UnorderedList>
                      {/* {peopleData.people.map((people, index) => (
                        <div
                          key={`people-${index}`}
                          className="mb-4 flex gap-[10px]"
                        >
                          <div className="relative w-10 h-10">
                            <img
                              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/members${people.photo}`}
                            />
                          </div>
                          <div>
                            <Text {...typography?.smallBold}>
                              {t(people.name)}
                            </Text>
                            {people.position === 'CEO' ? (
                              <Text {...typography?.extraSmall}>
                                {people.position}
                              </Text>
                            ) : (
                              <Text {...typography?.extraSmall}>
                                {t(people.position)}
                              </Text>
                            )}
                          </div>
                        </div>
                      ))} */}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </section>
    );
  }
);

export { QualityOfOrganization };
