import { Text, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'ui';

const DevelopmentMethod = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const router = useRouter();
    const [isMobile] = useMediaQuery('(max-width:768px)');

    return (
      <section
        id={id}
        ref={ref}
        className="mr-0 md:mb-16 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
      >
        <div className="mb-4 border-l-[6px] border-primary-500 pl-4 md:mb-[52px]">
          <Text
            as="h4"
            {...(isMobile ? typography?.h5 : typography?.h4)}
            className="mb-4 text-gray-primary "
          >
            {t('Development Method')}
          </Text>
          <Text className="whitespace-pre-line text-gray-secondary">
            {t(
              'By incorporating agile development, we will review deliverables every 1-2 weeks. Issues such as recognition discrepancies, incomplete requirements, and UI/UX can be identified early and flexibly addressed. \nIn principle, all members of the team regularly conduct KPT reflections on all cycles of business co-creation, including upstream processes, development, and operations.'
            )}
          </Text>
        </div>
        <div className="relative m-auto h-[129px] max-w-[874px] sm:h-[200px] md:h-[250px] lg:h-[358px]">
          <Image
            alt={t('Development Method Image')}
            src={
              router.locale === 'ja'
                ? '/development_method_ja.svg'
                : '/development_method.svg'
            }
            layout="fill"
          />
        </div>
      </section>
    );
  }
);

export { DevelopmentMethod };
