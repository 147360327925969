import { extendTheme } from '@chakra-ui/react';
import tailwindConfig from './tailwind.config';

// @ts-ignore
import resolveConfig from 'tailwindcss/resolveConfig';

const twConfig = resolveConfig(tailwindConfig);

export const typography = {
  normal: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
  },
  normalBold: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 700,
  },
  medium: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
  },
  mediumBold: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 600,
  },
  small: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: 400,
  },
  smallBold: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: 600,
  },
  extraSmall: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 400,
  },
  h1: {
    fontSize: 56,
    lineHeight: '66px',
    fontWeight: 700,
  },
  h2: {
    fontSize: 48,
    lineHeight: '58px',
    fontWeight: 700,
  },
  h3: {
    fontSize: 40,
    lineHeight: '48px',
    fontWeight: 700,
  },
  h4: {
    fontSize: 32,
    lineHeight: '38px',
    fontWeight: 700,
  },
  h5: {
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 700,
  },
  h6: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 600,
  },
  fontPoppins: {
    fontFamily: "'Poppins', sans-serif",
  },
  fontRubik: {
    fontFamily: "'Rubik', sans-serif",
  },
};

export const theme = extendTheme({
  fonts: {
    body: "'Poppins', sans-serif",
  },
  colors: {
    primary: twConfig.theme.colors?.primary,
    gray: twConfig.theme.colors?.gray,
  },
});
