import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { typography } from 'ui';

interface LocationProps {
  title: string;
  locationMD: string;
  locationSM: string;
  map: string;
}

export const LocationCard = ({
  title,
  locationMD,
  locationSM,
  map,
}: LocationProps) => {
  const { t } = useTranslation();
  return (
    <div className="gap-[6px] whitespace-pre-line text-gray-primary md:flex">
      <Text {...typography?.mediumBold} className="inline">
        {t(`${title} : `)}
      </Text>
      <Text
        as="a"
        {...typography?.mediumBold}
        className="hidden hover:underline md:flex"
        href={map}
        target={'_blank'}
      >
        {t(locationMD)}
      </Text>
      <Text
        as="a"
        href={map}
        target={'_blank'}
        {...typography?.mediumBold}
        className="inline md:hidden"
      >
        {t(locationSM)}
      </Text>
    </div>
  );
};
