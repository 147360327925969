import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { typography } from 'ui';

import Email from '../../../public/contact/mail.svg';
import Phone from '../../../public/contact/phone.svg';

type Props = {
  data: any;
  position: string;
};

export const JoinUsSuccess = ({ data, position }: Props) => {
  const { t } = useTranslation();
  useEffect(() => {
    var element = document.getElementById('join-success');
    if (element == null) return;
    var headerOffset = 96;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }, []);
  return (
    <section
      id="join-success"
      className="mb-10 flex flex-col justify-between md:flex-row "
    >
      <div className="h-[fit-content] w-[100%] border-l-[6px] border-primary-500 pl-4 md:w-7/12">
        <Text
          as="h3"
          className="font-Rubik text-2xl font-bold leading-[30px] text-gray-primary lg:text-[32px] lg:leading-[38px]"
        >
          {t(`Application for ${position}`)}
        </Text>
        <Text className="mt-4 whitespace-pre-line font-Poppins text-lg leading-[26px] text-gray-secondary">
          {t('Thank you for your application.')}
        </Text>
        <Text className="whitespace-pre-line font-Poppins text-lg leading-[26px] text-gray-secondary">
          {t(
            'We will review your application and notify you the result within 5 business days.'
          )}
        </Text>
        <Text className="whitespace-pre-line font-Poppins text-lg leading-[26px] text-gray-secondary">
          {t('Please wait for a while.')}
        </Text>
      </div>
      <div className="mt-5 w-[100%] rounded-lg bg-[#EAF4FD] p-[32px] md:mt-0 md:ml-[1rem] md:w-5/12">
        <Text as="p" {...typography?.medium}>
          {t('Your contact information')}
        </Text>
        <Text as="p" className="mt-[24px] flex" {...typography?.normal}>
          <Email className="mt-1" /> &nbsp; {t('Email')}: {data.email}
        </Text>
        <Text as="p" {...typography?.normal} className="mt-[16px] flex ">
          <Phone className="mt-1" /> &nbsp; {t('Phone')}: {data.phone}
        </Text>
      </div>
    </section>
  );
};
