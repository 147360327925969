import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'ui';

const TopTechnology = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section
        id={id}
        ref={ref}
        className="mt-8 mb-16 features lg:mx-0 lg:px-0 "
      >
        <div className="mb-10 border-l-[6px] border-primary-500 sm:mb-8">
          <Text
            as="h1"
            className="pl-4 mb-4 text-2xl font-bold leading-8 font-Rubik text-black-secondary md:text-medium md:leading-medium"
          >
            {t('Modern and Polished Technology')}
          </Text>
          <Text
            as="p"
            className="pl-4 font-Poppins text-gray-secondary"
            {...typography?.medium}
          >
            {t(
              'Secure, productive, and high-quality development allows us to develop and grow businesses in a reliable and sustainable manner.'
            )}
          </Text>
        </div>
        <video
          playsInline
          className=""
          muted
          height={'auto'}
          width={'100%'}
          data-testid="video-asset"
          role="video"
          src={`${process.env.NEXT_PUBLIC_STATIC_URL}/videos/technology.mp4`}
          controls={false}
          autoPlay={true}
          loop={true}
        />
      </section>
    );
  }
);

export { TopTechnology };
