import { Text } from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { ElementType } from 'react';
import { typography } from 'ui';

interface ServiceCardProps {
  icon: ElementType;
  title: string;
  description: string;
  link?: string;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  icon: Icon,
  title,
  description,
  link,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#f9fafb] p-[30px]">
      <Icon />
      <Text className="mt-4 mb-3" {...typography?.h5}>
        {t(title)}
      </Text>
      <Text
        {...typography?.medium}
        className="whitespace-pre-wrap text-gray-secondary"
      >
        {t(description)}
      </Text>
      {link && (
        <Link href={link} passHref>
          <a className="flex items-center mt-3 space-x-1 text-lg font-bold max-w-max text-primary-500">
            <span>{t('Learn More')}</span>
            <ArrowRightIcon className="w-6 h-6" />
          </a>
        </Link>
      )}
    </div>
  );
};
