import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { typography } from 'ui';

export const QualityTop = () => {
  const { t } = useTranslation();

  return (
    <section className="features mt-[40px] lg:mx-0 lg:mb-16 lg:px-0 ">
      <div className=" border-l-[6px] border-primary-500 sm:mb-8">
        <Text
          as="h1"
          className="pl-4 mb-4 text-2xl font-bold leading-8 font-Rubik text-black-secondary md:text-medium md:leading-medium"
        >
          {t('Quality Assurance')}
        </Text>
      </div>
      <Text
        as="h2"
        className="mb-4  font-Rubik text-xl font-bold text-gray-primary lg:text-2xl lg:leading-[30px]"
      >
        {t('Customer Experience and Service Quality')}
      </Text>
      <Text
        as="p"
        className="mb-4 mr-0 font-Poppins text-gray-secondary lg:mb-8 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
        {...typography?.medium}
      >
        {t(
          'We are taking multifaceted measures to reproduce and continually raise levels of customer experience and service quality. We continue to update ourselves while never being satisfied with the status quo.'
        )}
      </Text>
      <video
        playsInline
        className=""
        muted
        height={'auto'}
        width={'100%'}
        data-testid="video-asset"
        role="video"
        src={`${process.env.NEXT_PUBLIC_STATIC_URL}/videos/quality.mp4`}
        controls={false}
        autoPlay={true}
        loop={true}
      />
    </section>
  );
};
