import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Text } from '@chakra-ui/react';
import { Button } from 'components/atoms';
import { typography } from 'ui';
import { PageProps } from 'shared/interfaces';
import { NewsCard } from 'components/molecules';
import { NEWS } from 'constants/news';

const parseDate = (dateString: string): number => {
  const [year, month] = dateString.split(' ');
  const parsedDate = new Date(Number(year), new Date(`${month} 1`).getMonth());
  return parsedDate.getTime();
};

const News = forwardRef(
  ({ id, className }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const [expand, setExpand] = useState(false);

    const sortedNews = useMemo(() => {
      return NEWS.slice().sort((a, b) => {
        if (parseDate(b.date) === parseDate(a.date)) {
          return Number(b.id) - Number(a.id);
        }
        return parseDate(b.date) - parseDate(a.date);
      });
    }, []);

    const newsList = expand ? sortedNews : sortedNews.slice(0, 6);

    useEffect(() => {
      const storedExpand = sessionStorage.getItem('expandNews') === 'true';
      setExpand(storedExpand);

      window.addEventListener('beforeunload', () => {
        sessionStorage.removeItem('expandNews');
      });

      return () => {
        window.removeEventListener('beforeunload', () => {
          sessionStorage.removeItem('expandNews');
        });
      };
    }, []);

    const handleExpandClick = () => {
      setExpand(true);
      sessionStorage.setItem('expandNews', 'true');
    };

    return (
      <section
        id={id}
        ref={ref}
        className={`m-auto mt-16 flex max-w-xl flex-col ${className}`}
      >
        <div className="md:pr-0 lg:pr-6 xl:pr-0">
          <div className="pl-0 md:border-l-[6px] md:border-[#3767B1] md:pl-[16px]">
            <Text
              {...typography?.fontPoppins}
              className="border-l-[6px] border-[#3767B1] pl-[16px] text-2xl font-bold md:border-l-0 md:border-none md:pl-0 md:text-3xl"
            >
              {t('News')}
            </Text>
          </div>
          <div className="mt-[32px] grid grid-flow-row grid-cols-1 gap-[32px] md:grid-cols-2 lg:mr-8 lg:grid-cols-3 lg:gap-[32px]">
            {newsList.map((news) => (
              <NewsCard
                id={news.id}
                key={news.id}
                img={news.thumbnail}
                link={news.link}
                date={t(news.date)}
                title={t(news.title)}
                label={t(news.label)}
                slug={news.slug}
              />
            ))}
          </div>
          <div className="mt-[4rem] flex items-center justify-center md:mt-[32px]">
            {!expand && (
              <Button
                type="secondary"
                className="!w-full md:!w-auto"
                onClick={handleExpandClick}
              >
                {t('View past news')}
              </Button>
            )}
          </div>
        </div>
      </section>
    );
  }
);

export { News };
