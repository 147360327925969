import { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  List,
  ListItem,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { typography } from 'ui';
import { vars } from 'config';
import { DownArrow, ChervonUp, DownArrowWhite } from 'public/assets/images/svg';

type Props = {
  isNavExpanded?: boolean;
  isFooterActive?: boolean;
  listItemClassName?: string;
  buttonClassName?: string;
};

export const LanguageProvider = ({
  buttonClassName,
  isFooterActive,
  isNavExpanded,
  listItemClassName,
}: Props) => {
  const [popUp, setPopUp] = useState<boolean>(false);

  const { i18n } = useTranslation();
  const router = useRouter();
  let path = router.asPath;
  const isLocal = process?.env?.NEXT_PUBLIC_APP_ENV === 'local';

  const handleActiveLocal = () => {
    setPopUp(false);

    if (isLocal) {
      router.push(
        {
          pathname: router.pathname,
          query: router.query,
        },
        router.asPath,
        { locale: 'en' }
      );
      return;
    }
    window.location?.assign(`https://en.wesionary.team${path}`);
  };
  const handleActiveLocalForJA = () => {
    setPopUp(false);
    if (isLocal) {
      router.push(
        {
          pathname: router.pathname,
          query: router.query,
        },
        router.asPath,
        { locale: 'ja' }
      );
      return;
    }
    window.location?.assign(`https://wesionary.team${path}`);
  };

  return (
    <Popover
      autoFocus={false}
      isLazy
      onClose={() => setPopUp(false)}
      isOpen={popUp}
    >
      {/*// @ts-ignore */}
      <PopoverTrigger>
        <div
          onClick={() => setPopUp((prev: boolean) => !prev)}
          className="block cursor-pointer"
        >
          <div
            style={{
              ...typography?.fontPoppins,
            }}
            className={`mr-8 ml-2 flex text-base font-semibold ${
              isNavExpanded || isFooterActive ? 'text-white' : 'text-gray-900'
            } ${buttonClassName}`}
          >
            {i18n.language === vars.locales.en ? 'English' : '日本語'}
            &nbsp;&nbsp;
            <div className="mt-2">
              {isNavExpanded ? (
                <>
                  {popUp ? (
                    <div className="rotate-180">
                      <DownArrowWhite />
                    </div>
                  ) : (
                    <DownArrowWhite />
                  )}
                </>
              ) : (
                <>
                  {!popUp ? (
                    isFooterActive ? (
                      <DownArrowWhite />
                    ) : (
                      <DownArrow />
                    )
                  ) : isFooterActive ? (
                    <DownArrowWhite /> ///Change this once light version of up icon available
                  ) : (
                    <ChervonUp />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="rounded-[4px] border bg-[#EDF1F7] py-[0.5rem]">
        <List>
          <ListItem
            onClick={() => handleActiveLocal()}
            {...typography?.fontPoppins}
            className={`h-10  cursor-pointer py-[10px] px-[2rem] hover:bg-[#FFFFFF] ${
              router.locale === 'en'
                ? 'border-l-2 border-l-[#3767B1] bg-[#FFFFFF]'
                : ''
            } ${listItemClassName}`}
          >
            <p className="flex text-[#000]">English</p>
          </ListItem>
          <ListItem
            onClick={() => handleActiveLocalForJA()}
            {...typography?.fontPoppins}
            className={`h-10 cursor-pointer py-[5px] px-[2rem] pt-2 hover:bg-[#FFFFFF] ${
              router.locale === 'ja'
                ? 'border-l-2 border-l-[#3767B1] bg-[#FFFFFF]'
                : ''
            } ${listItemClassName}`}
            display="flex"
          >
            <p className="flex">日本語</p>
          </ListItem>
        </List>
      </PopoverContent>
    </Popover>
  );
};
