import { Img, Text } from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';
import { typography } from 'ui';

interface FeaturesItem {
  heading: string;
  paragraph: string;
  hoverClass: string;
  imageName: string;
  imageAlt: string;
  index: number;
  selected: boolean;
  handleImageChange: (index: number) => void;
}

const FeaturesDesc = ({
  heading,
  paragraph,
  imageName,
  imageAlt,
  index,
  selected,
  handleImageChange,
}: FeaturesItem) => {
  const { t } = useTranslation();

  return (
    <div
      className={`mb-4 rounded-l-md sm:mb-8 lg:cursor-pointer lg:p-4 ${
        selected && 'lg:bg-[#E5F0FF]'
      }`}
      onMouseOver={() => handleImageChange(index)}
    >
      <div className="flex items-center mb-3">
        <Text
          as="h4"
          className="mr-auto font-Rubik text-black-secondary md:whitespace-pre"
          {...typography?.h6}
        >
          {t(heading)}
        </Text>

        <ArrowRightIcon
          className={`hidden h-[20px] w-[20px] cursor-pointer lg:block  ${
            selected ? 'text-primary-500' : 'text-gray-500'
          }`}
        />
      </div>
      <Text
        as="p"
        {...typography?.small}
        className="mb-4 font-Poppins text-gray-secondary"
      >
        {t(paragraph)}
      </Text>
      <div className="w-full mt-8 text-center lg:hidden">
        <Img
          height="100%"
          width="100%"
          className="object-contain w-full max-w-xl m-auto"
          src={`${process.env.NEXT_PUBLIC_STATIC_URL}/features/${imageName}.png`}
          alt={t(imageAlt)}
        />
      </div>

      <hr className="mt-8 border-t border-gray-500 lg:hidden" />
    </div>
  );
};

export { FeaturesDesc };
