import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { SliderProps } from '../../../constants/works';
import { typography } from 'ui';
import { WorkProgressLoader } from '../../atoms';

interface Props {
  work: SliderProps;
  index: number;
  isActive?: boolean;
  onClick: (index: number) => void;
}

const WorkCard = ({ work, index, isActive, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <article
      key={index}
      onClick={() => onClick(index)}
      className={`relative flex h-[87px] cursor-pointer items-center border-b border-r border-t border-l border-solid border-primary-300 border-opacity-50 md:h-[118px] md:border-b-0 md:border-r-0 ${
        isActive && 'bg-primary-500'
      }`}
    >
      <div className="px-[18px]">
        {isActive ? (
          <>
            <div className="mb-2 flex items-center">
              {work.icon}
              <Text className="ml-2" {...typography?.mediumBold}>
                {t(work.title)}
              </Text>
            </div>
            <Text {...typography?.small}>{t(work.description)}</Text>
          </>
        ) : (
          <div className="flex items-center gap-2 md:block">
            <div className="md:mb-3">{work.icon}</div>
            <Text {...typography?.mediumBold}>{t(work.title)}</Text>
          </div>
        )}
      </div>
      {isActive && <WorkProgressLoader isActive={isActive} />}
    </article>
  );
};

export { WorkCard };
