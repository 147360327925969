import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  FormControl,
  Text,
  Button as ChakraButton,
  useToast,
  Box,
} from '@chakra-ui/react';

import { typography } from 'ui';
import { Button, TextField, Select, Upload } from 'components/atoms';

import {
  InfoCircle,
  UserCircular,
  AttachementIcon,
  UploadIcon,
} from 'public/assets/images/svg';

import { KNOWABOUTUS } from '../../../utils/contact';

import Name from '../../../public/contact/name.svg';
import Email from '../../../public/contact/mail.svg';
import Phone from '../../../public/contact/phone.svg';

import { firebase } from '../../../utils/firebase';

import { captureException } from '@sentry/nextjs';
import { handleRecaptcha } from 'utils/recaptcha';

type Props = {
  position: string;
  showSuccess: boolean;
  setShowSuccess: Function;
  setData: Function;
};

export const JoinUsForm = ({ setShowSuccess, setData, position }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [submitError, setSubmitError] = useState(false);
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const schema = yup.object().shape({
    firstName: yup.string().required(t('Please enter first name')),
    lastName: yup.string().required(t('Please enter last name')),
    email: yup
      .string()
      .required(t('Please enter Email address'))
      .email(t('Invalid email format')),
    phone: yup
      .string()
      .required(t('Please enter phone number'))
      .matches(phoneRegExp, t('Please enter a valid phone number')),
    knowAboutUs: yup.string().required(t('Please select the item')),
    interviewAgree: yup
      .bool()
      .oneOf([true], t('Please agree to record the interview'))
      .required(t('Please agree to record the interview')),
    privacyAgree: yup
      .bool()
      .oneOf([true], t('Please agree to Recruitment Privacy Policy'))
      .required(t('Please agree to Recruitment Privacy Policy')),
    documents: yup
      .mixed()
      .nullable()
      .notRequired()
      .test('fileSize', `${t('File size larger than 10MB')}`, (value: any) => {
        return value && value[0] ? value[0].size <= 10000000 : true;
      })
      .test('type', `${t('Please select PDF file')}`, function (value: any) {
        return value && value[0] ? value[0].type === 'application/pdf' : true;
      }),
    resume: yup
      .mixed()
      .test('required', `${t('Please upload a file')}`, (value: any) => {
        return value && value.length;
      })
      .test('fileSize', `${t('File size larger than 10MB')}`, (value: any) => {
        return value && value[0] && value[0].size <= 10000000;
      })
      .test('type', `${t('Please select PDF file')}`, function (value: any) {
        return value && value[0] ? value[0].type === 'application/pdf' : true;
      }),
  });

  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    mode: 'all',
    defaultValues: {
      referredBy: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const first = Object.keys(errors)[0];
    const currentEle = document?.getElementById(first);
    currentEle?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    currentEle?.focus();
  }, [errors]);

  const onSubmit = async (data: any) => {
    const isRecaptchaPass = await handleRecaptcha(
      'JOIN_US',
      process.env.NEXT_PUBLIC_RECAPTCHA_KEY
    );
    if (!isRecaptchaPass) {
      toast({
        position: 'bottom-left',
        render: () => (
          <Box className="p-6 bg-primary-400">
            <Text className="text-white">
              {t(
                'Unable to pass recaptcha verification.Please try again later.'
              )}
            </Text>
          </Box>
        ),
      });
      return;
    }
    try {
      let storeData = { ...data, resumeURL: '' };
      delete storeData.resume;
      delete storeData.documents;
      await firebase
        .storage()
        .ref(
          `/applications/${data?.firstName + '_' + data?.lastName}_resume.pdf`
        )
        .put(data.resume[0])
        .then(async (snapshot: any) => {
          await snapshot.ref.getDownloadURL().then((downloadURL: any) => {
            console.log(downloadURL, 'ppp');
            storeData.resumeURL = downloadURL;
          });
        });
      if (data.documents && data.documents[0]) {
        await firebase
          .storage()
          .ref(
            `/applications/${data?.firstName + '_' + data?.lastName}_other.pdf`
          )
          .put(data.documents[0])
          .then(async (snapshot: any) => {
            await snapshot.ref.getDownloadURL().then((downloadURL: any) => {
              storeData = {
                ...storeData,
                other: downloadURL,
              };
            });
          });
      }

      await firebase
        .firestore()
        .collection('applications')
        .add({
          ...storeData,
          local: t('local'),
          position: position,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
      captureException(error);
      setSubmitError(true);
      return;
    }

    setShowSuccess(true);
    setData({ email: data.email, phone: data.phone });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl className="grid gap-2 lg:max-w-[600px]">
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              errorMessage={errors?.firstName?.message as string}
              {...field}
              id="firstName"
              icon={<Name />}
              required
              placeholder={t('eg. John')}
              label={t('First Name')}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              id="lastName"
              errorMessage={errors?.lastName?.message as string}
              {...field}
              icon={<Name />}
              required
              placeholder={t('eg. Smith')}
              label={t('Last Name')}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              id="email"
              errorMessage={errors?.email?.message as string}
              {...field}
              icon={<Email />}
              required
              placeholder={t('eg. john@wesionary.team')}
              label={t('Email Address')}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              errorMessage={errors?.phone?.message as string}
              {...field}
              id="phone"
              required
              type="phone"
              icon={<Phone />}
              label={t('Phone Number')}
              placeholder={t('eg. 9812345678')}
            />
          )}
        />
        <Upload
          register={register('resume')}
          accept="application/pdf"
          label={t('Attach Resume')}
          required
          icon={<AttachementIcon />}
          errorMessage={errors?.resume?.message as string}
          id="resume"
        >
          <div className="flex flex-col">
            {watch('resume')?.[0] && (
              <Text as="p">{watch('resume')?.[0]?.name}</Text>
            )}
            <div className="flex">
              <ChakraButton
                size="md"
                width="200px"
                border="2px"
                className="upload"
                borderColor="#960912"
                rightIcon={<UploadIcon />}
              >
                {t('Select File')}
              </ChakraButton>
              <Text
                {...typography?.small}
                className="text-gray-secondary ml-3 pt-[1.5rem]"
              >
                {t('Maximum size: 10MB, File format: pdf')}
              </Text>
            </div>
          </div>
        </Upload>
        <Upload
          register={register('documents')}
          accept="application/pdf"
          label={t('Attach other documents')}
          icon={<AttachementIcon />}
          errorMessage={errors?.documents?.message as string}
          id="documents"
        >
          <div className="flex flex-col">
            {watch('documents')?.[0] && (
              <Text as="p">{watch('documents')?.[0]?.name}</Text>
            )}
            <div className="flex">
              <ChakraButton
                size="md"
                width="200px"
                border="2px"
                className="upload"
                rightIcon={<UploadIcon />}
              >
                {t('Select File')}
              </ChakraButton>
              <Text
                {...typography?.small}
                className="text-gray-secondary ml-3 pt-[1.5rem]"
              >
                {t('Maximum size: 10MB, File format: pdf')}
              </Text>
            </div>
          </div>
        </Upload>
        <Controller
          name="knowAboutUs"
          control={control}
          render={({ field }) => (
            <Select
              errorMessage={errors?.knowAboutUs?.message as string}
              {...field}
              id="knowAboutUs"
              required
              options={KNOWABOUTUS}
              icon={<InfoCircle />}
              label={t('How do you know about us?')}
              placeholder={t('Choose an option')}
            />
          )}
        />
        <Controller
          name="referredBy"
          control={control}
          render={({ field }) => (
            <TextField
              errorMessage={errors?.referredBy?.message as string}
              {...field}
              id="referredBy"
              type="text"
              labelDescription={t(
                "If your application is a referral from a member wesionaryTEAM,  please enter the members' name."
              )}
              icon={<UserCircular />}
              label={t('Who referred you?')}
              placeholder={t('eg. John Smith')}
            />
          )}
        />
        <hr className=" my-[20px] border-[#8F9BB3]" />
        <Text {...typography?.medium}>
          {t('Agreement to record the interview')}
          <Text
            as="span"
            className="font-Poppins text-gray-secondary pl-[3px] text-[18px] font-normal leading-[26px]"
          >
            {t('(Required)')}
          </Text>
        </Text>
        <Text
          {...typography?.extraSmall}
          className="text-gray-secondary mb-[16px] mt-[10px]"
        >
          {t(
            'Interviews are recorded in order to allow managers and executives who were unable to attend the interview to review the interview at a later time. Please agree to this before applying.'
          )}
        </Text>
        <Controller
          name="interviewAgree"
          control={control}
          render={({ field }) => (
            <div className="h-14">
              <Checkbox
                id="interviewAgree"
                {...field}
                className="font-Poppins mb-4 block !text-[16px] font-normal text-[#222B45] lg:mb-6"
                value="interviewAgree"
              >
                {t('I agree to the recording of interview.')}
              </Checkbox>

              {errors?.interviewAgree?.message && (
                <Text
                  className="mb-6 mt-[-1rem] text-[#960912]"
                  height="25px"
                  as="p"
                >
                  {errors?.interviewAgree?.message as string}
                </Text>
              )}
            </div>
          )}
        />
        <hr className="my-[20px] border-[#8F9BB3]" />
        <Text {...typography?.medium}>
          {t('Privacy Policy Agreement')}
          <Text
            as="span"
            className="font-Poppins text-gray-secondary pl-[3px] text-[18px] font-normal leading-[26px]"
          >
            {t('(Required)')}
          </Text>
        </Text>
        <Text
          {...typography?.extraSmall}
          className="text-gray-secondary mb-[20px] mt-[10px] "
        >
          {t(
            'Please review our privacy policy for recruitment activities and apply if you agree with the policy.'
          )}
        </Text>
        <Controller
          name="privacyAgree"
          control={control}
          render={({ field }) => (
            <div className="h-14">
              <Checkbox
                id="privacyAgree"
                {...field}
                value="privacyAgree"
                className="font-Poppins mb-8  block !text-[16px] font-normal text-[#222B45] lg:mb-6"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "I agree to the <a href='/recruitment-privacy-policy' target='_blank' rel='noopener noreferrer' class='decoration font-Poppins !text-lg text-primary-500 underline'>Recruitment Privacy Policy</a>"
                    ),
                  }}
                />
              </Checkbox>

              {(errors?.privacyAgree?.message as string) && (
                <Text
                  className="mb-6 mt-[-1rem] text-[#960912]"
                  height="25px"
                  as="p"
                >
                  {errors?.privacyAgree?.message as string}
                </Text>
              )}
            </div>
          )}
        />
        <br />
        <Button
          htmlType="submit"
          className="w-[5rem]"
          type="primary"
          btnVarient="Other"
          isLoading={isSubmitting}
        >
          {t('Submit form')}
        </Button>
      </FormControl>
      {submitError && (
        <Text className="mb-6 mt-6 text-[#960912]" height="25px" as="p">
          {t(
            'Something is technically wrong. Our development team has been notified of this issue. We’re going to fix it up and have things back to normal soon. If the problem persists after some time, please contact us. Email: website_issue@wesionary.team'
          )}
        </Text>
      )}
    </form>
  );
};
